<template>
  <div class="row m-0 abs">
    <div class="col-12">
      <div class="row">
        <div class="col-12 bold smallheading my-4">
          Add Partnership
        </div>
        <div class="col-12 col-md-6 mb-2">
          <TextField
            type="text"
            color="gold"
            placeholder="Name"
            v-model="thePartnership.companyName"
            id="name"
            label="Company Name:"
          />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <CurrencyField
            type="text"
            placeholder="0.00"
            id="worth"
            color="gold"
            v-model="thePartnership.netWorth"
            :currencySymbol="defaultCurrency"
            :label="'Net Worth In ' + defaultCurrencyName + ':'"
          />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <PercentageField
            type="text"
            color="gold"
            placeholder="Percentage Owned"
            v-model="thePartnership.percentageOwned"
            id="percentage"
            label="Percentage Owned:"
          />
        </div>
        <div class="col-12 bold smallheading my-4">
          Total: {{defaultCurrency}}{{parseFloat(Math.round((calculatedAmount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
        </div>
        <div class="col-12 my-4" v-if="isEdit">
          <Button color="green" btnText="Update" icon="arrow" @buttonClicked="update" class="mb-1" >
            <IconArrowForward color="gold" />
          </Button>
          <Button color="transparent" btnText="Cancel" @buttonClicked="cancel" class="mb-1 ms-3" >
          </Button>
        </div>
        <div class="col-12 my-4" v-else>
          <Button color="green" btnText="Save" icon="arrow" @buttonClicked="save" class="mb-1" :disabled="calculatedAmount < 0.01" >
            <IconArrowForward color="gold" />
          </Button>
        </div>
      </div>
      <div class="row" v-if="partnershipAssets.length > 0">
        <div class="col-12 bold smallheading my-4">
          Partenrships
        </div>
      </div>
      <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in partnershipAssets" :key="key">
        <div class="col-12">
          <div class="row d-none d-md-flex align-items-center">
            <div class="col">
              <div class="row">
                <div class="col-12 bold mb-1">
                  {{asset.companyName}}
                </div>
                <div class="col-12 font12">
                  {{defaultCurrency}}{{parseFloat(Math.round((asset.netWorth) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }} | {{asset.percentageOwned}}%
                </div>
              </div>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" btnText="EDIT" icon="arrow" size="xsmall" @buttonClicked="editIndividual(key, asset)">
                <IconEdit size="size16" />
              </Button>
            </div>
            <div class="col-auto text-end bold">
              {{defaultCurrency}}{{parseFloat(Math.round((asset.total) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="small" @buttonClicked="showDelete(key, asset)">
                <ios-trash-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
          <div class="row d-md-none">
            <div class="col">
              <div class="row">
                <div class="col-12 bold mb-1">
                  {{asset.companyName}}
                </div>
                <div class="col-12 font12">
                  {{defaultCurrency}}{{parseFloat(Math.round((asset.netWorth) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }} | {{asset.percentageOwned}}%
                </div>
                <div class="col-12 bold mt-2">
                  {{defaultCurrency}}{{parseFloat(Math.round((asset.total) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                </div>
              </div>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="xxsmall" @buttonClicked="editIndividual(key, asset)">
                <IconEdit />
              </Button>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="xxsmall" @buttonClicked="showDelete(key, asset)">
                <ios-trash-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <Alert v-if="isDelete" title="Are you sure you want to delete this partnership?" :message="thePartnership.companyName" @close="closeDelete" button1="Delete" button2="Cancel" align="center" @doAction2="closeDelete" @doAction1="deleteItem" key="alert"></Alert>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Alert: defineAsyncComponent(() => import('../../../components/Alert.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    CurrencyField: defineAsyncComponent(() => import('@/components/CurrencyField.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue')),
    PercentageField: defineAsyncComponent(() => import('@/components/PercentageField.vue')),
    'ios-trash-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-trash.vue'))
  },
  name: 'AddPartnership',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      defaultCurrencyName: process.env.VUE_APP_CURRENCY,
      thePartnership: {
        companyName: '',
        netWorth: null,
        percentageOwned: null,
        total: null
      },
      isEdit: false,
      isDelete: false,
      currentKey: null
    }
  },
  computed: {
    ...mapGetters([
      'partnershipAssets'
    ]),
    calculatedAmount () {
      let value = 0
      if (this.thePartnership.netWorth && this.thePartnership.percentageOwned) {
        value = this.thePartnership.netWorth * (parseFloat(this.thePartnership.percentageOwned) / 100)
      }
      return value
    }
  },
  methods: {
    ...mapActions([
      'addToPartnership',
      'editPartnership',
      'deletePartnershipItem',
      'clearPartnership'
    ]),
    showDelete (key, val) {
      this.thePartnership = val
      this.currentKey = key
      this.isDelete = true
    },
    closeDelete  () {
      this.thePartnership = {
        companyName: '',
        netWorth: null,
        percentageOwned: null,
        total: null
      }
      this.currentKey = null
      this.isDelete = false
    },
    deleteItem () {
      this.deletePartnershipItem(this.currentKey)
      this.thePartnership = {
        companyName: '',
        netWorth: null,
        percentageOwned: null,
        total: null
      }
      this.currentKey = null
      this.isDelete = false
    },
    cancel () {
      this.thePartnership = {
        companyName: '',
        netWorth: null,
        percentageOwned: null,
        total: null
      }
      this.isEdit = false
      this.currentKey = null
    },
    editIndividual (key, val) {
      this.isEdit = true
      this.thePartnership.netWorth = val.netWorth
      this.thePartnership.companyName = val.companyName
      this.thePartnership.percentageOwned = val.percentageOwned
      this.thePartnership.total = val.total
      this.currentKey = key
    },
    async update () {
      this.thePartnership.total = parseFloat(this.calculatedAmount)
      const savePartnership = {
        key: '',
        item: {
          percentageOwned: 0,
          total: 0,
          companyName: '',
          netWorth: ''
        }
      }
      savePartnership.key = this.currentKey
      savePartnership.item.percentageOwned = this.thePartnership.percentageOwned
      savePartnership.item.total = this.thePartnership.total
      savePartnership.item.companyName = this.thePartnership.companyName
      savePartnership.item.netWorth = this.thePartnership.netWorth
      this.editPartnership(savePartnership)
      this.thePartnership = {
        companyName: '',
        netWorth: null,
        percentageOwned: null,
        total: null
      }
      this.isEdit = false
      this.currentKey = null
    },
    async save () {
      this.thePartnership.total = parseFloat(this.calculatedAmount)
      const savePartnership = {
        percentageOwned: 0,
        total: 0,
        companyName: '',
        netWorth: ''
      }
      savePartnership.percentageOwned = this.thePartnership.percentageOwned
      savePartnership.total = this.thePartnership.total
      savePartnership.companyName = this.thePartnership.companyName
      savePartnership.netWorth = this.thePartnership.netWorth
      this.addToPartnership(savePartnership)
      this.thePartnership = {
        companyName: '',
        netWorth: null,
        percentageOwned: null,
        total: null
      }
    }
  }
}
</script>

<style scoped>
.smallheading {
  color: var(--green-color);
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
}
</style>
